// Brand Colors
$primary: #7d3f98;
$secondary: #7d3f98;
$tertiary: #7d3f98;

// * Color used for all font sizes except the smallest
$foreground-primary: #414141;
$foreground-primary-light: #6e6e6e;


// * Color used for the smallest font size, shadows, icons, dividers, borders, and other elements
$foreground-secondary: #6e6e6e;
// * Color used for errors, alerts, emergency, and other important messages
$alert: #e10506;

// * Background colors
$background-primary-light: #f2ebf4;
$background-primary-lighter: #f8f5fa;
$background-neutral: #f5f5f5;
$background-highlight: #fff4d7;
$background-secondary-light:  #f5f5f5;
$background-subtitle-color: rgba(128, 128, 128, 0.5);

// Message Bubble colors
$bubble-container-border: #63666a26;
$bubble-box-shadow: #4b4b5026;
$bubble-background: #ffffff;
$emergency-button-background: #e020200a;

// Popup colors
$popup-border-bottom: #63666a33;
$popup-button: #ffffff;

// * Border colors
$border-primary: #e9e9e9;

:export {
  brandColorPrimary: $primary;
  brandColorForegroundPrimary: $foreground-primary;
  brandColorForegroundSecondary: $foreground-secondary;
  brandColorAlert: $alert;
}
